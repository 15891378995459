import React from 'react'
import Layout from '../../components/Layout'
import { Link } from 'gatsby'
import * as styles from '../../assets/css/surveys.module.css'

const SurveyGibbs = () => {

  return (
    <Layout>
      <section>
        <h1>Seaside Town Architect’s Survey</h1>
        <h2>Richard Gibbs</h2>
        <section>
          <p className={styles.bold}>When did you serve as Seaside Town Architect?</p>
          <p>In 1990 Robert Davis, the founder, asked me to take the position of Town Architect for Seaside. I accepted the position and remained there six years…</p>

          <p className={styles.bold}>What initially drew you to Seaside?</p>
          <p>In 1986 I designed and built a home in the new urbanist town of Seaside, Florida. In 1990 Robert Davis, the founder, asked me to take the position of Town Architect for Seaside.</p>

          <p className={styles.bold}>What do you consider your greatest accomplishments during your time as Town Architect?</p>
          <p>When Robert Davis hired me to be “Town Architect” the building of the town in terms of plan submittal for review had mostly subsided. My work load in terms of plan review was light and my time was spent mostly in my own private practice doing homes for residential clients. I drew plans for 15+ homes counting main houses and guest houses separately. In fact the classical house (4 big round columns and the Greek temple on the roof)… is one of my designs. I also designed “Sea Forever” a gulf front Prairie style house that Robert Davis later bought because he wanted to live in it. “Magnetic Attraction” is another house I am quite proud of.</p>

          <p className={styles.bold}>What were common challenges you found while working in Seaside?</p>
          <p>The challenge of working in Seaside was always the balance between the desires of the client and the requirements of the Urban Code. As the enforcer of the code I had to perhaps be more conservative with my design work to not take special advantage of my position as the review entity.</p>

          <p className={styles.bold}>How do you feel about Seaside today?</p>
          <p>Seaside today is an exuberant holiday town! This is due in part to the continued existence of buildings that were thought of as temporary when they were built. In fact the six recently added Airstream trailers continue this lightheartedness. I think that the spirit of Seaside is much more about the beach atmosphere then places like Watercolor and Rosemary Beach.</p>

          <p>Do you consider yourself an Urbanist or New Urbanist? How do you feel Seaside fits in with modern urban philosophy?</p>
          <p>Having been first an Urbanist while at RISD and then a New Urbanist while living and working at Seaside and Rosemary Beach for 15 years (6 at Seaside), I now live in Louisiana in a two hundred year old house and consider myself a “Preservationist”! I sit on the Board of Directors for the Louisiana Trust for Historic Preservation.</p>

          <p>Can you give a brief summary of what you are doing today, including any recent projects, awards, or positions?</p>
          <p>In 2005 my partner and I purchased the LeJeune House, a plantation home built around 1813 and listed on the National Register of Historic Places. It is known locally as the oldest house in New Roads. We are actively restoring the home and grounds. Client work has included: renovation and restoration of two apartments at the Dakota, a National Landmark building in New York City; design of a new entrance building for Blithewold Mansion and Gardens, a historic 34-acre estate in Bristol, Rhode Island; design of an event pavilion at Eden State Gardens, an historic home and garden in Point Washington, Florida; more than thirty houses in New England; more than thirty buildings in Florida and Louisiana. A number of my projects have been published in magazines and books.</p>
          <hr />
        </section>
        <div className={styles.center}><Link to='/essays/town-architects'>Back to Town Architects</Link></div>
      </section>
    </Layout>
  )
}

export default SurveyGibbs
